import React, { useState, useEffect } from 'react';
import { getAttachmentBase64 } from '../../actions/jobAttachmentActions';
export default function AttachmentImage({ id, alt }) {
  const [img, setImg] = useState();

  const fetchImage = async () => {
    getAttachmentBase64(id).then((d) => {
      setImg(d);
    });
  };

  useEffect(() => {
    fetchImage();
  }, []);

  return (
    <>
      <img src={img} alt={alt} className="img-resp" />
    </>
  );
}
