import React, { useState, useEffect } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { getJobById, startJob } from '../../actions/jobActions';
import { saveComment } from '../../actions/jobCommentActions';
import {
  loadAttachmentsForJob,
  downloadAttachment,
} from '../../actions/jobAttachmentActions';

function StartJob({ currentUser, jobs }) {
  const params = useParams();
  const navigate = useNavigate();
  const [job, setJob] = useState();
  const [reloadJob, setReloadJob] = useState();
  const [jobAttachments, setJobAttachments] = useState();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (params.id) {
      // Get the job
      getJobById(params.id).then((j) => {
        setJob(j);
      });
    }
  }, [params.id, setJob, reloadJob]);

  useEffect(() => {
    if (params.id) {
      // Get the job
      loadAttachmentsForJob(params.id).then((d) => {
        setJobAttachments(d);
      });
    }
  }, [params.id, setJobAttachments]);

  useEffect(() => {
    if (jobs && jobs.find((x) => x.id === params.id)) {
      setReloadJob(new Date());
    }
  }, [jobs, params.id]);

  const handleDownload = (attachment) => {
    downloadAttachment(attachment.jobAttachmentId);
  };
  const handleStartJob = () => {
    let desc = jobAttachments
      .filter((x) => x.readRequiredOnStart)
      .map((x) => x.description ?? x.filename)
      .join(', ');
    setIsSaving(true);
    saveComment({
      jobId: params.id,
      comment: `I have read the following : ${desc}`,
      jobAttachmentIds: [],
    })
      .then(() => {
        startJob(params.id)
          .then(() => {
            toast.success('Job started');
            done();
            setIsSaving(false);
          })
          .catch((e) => {
            toast.error('Failed to start job');
            console.error(e);
            setIsSaving(false);
          });
      })
      .catch((e) => {
        toast.error('Failed to add comment');
        console.error(e);
        setIsSaving(false);
      });
  };

  const done = () => navigate(`/job/${params.id}`);
  const handleCancel = () => navigate(`/job/${params.id}`);

  return job && jobAttachments && !isSaving ? (
    <>
      <div className="container-fluid page-title">
        <span className="a" onClick={handleCancel}>
          <i className="fa-light fa-chevron-left text-white"></i>
          <h1>{job.workOrder}</h1>
        </span>
      </div>

      <div className="d-flex flex-column flex-1-0-auto overflow-y-scroll hide-scroll">
        <div className="container-fluid d-flex flex-wrap pb-3 pt-3">
          <div className="col">
            <label>Before you start..</label>
            <span>Have you read</span>
          </div>
          <div className="attachments btn-group-vertical w-100 mt-3">
            {jobAttachments && jobAttachments.length > 0 ? (
              jobAttachments
                .filter((x) => x.readRequiredOnStart)
                .map((x) => (
                  <button
                    key={x.jobAttachmentId}
                    type="button"
                    className="btn w-100"
                    onClick={() => handleDownload(x)}
                  >
                    <i className="fa-solid fa-circle-exclamation text-red"></i>
                    {x.description ?? x.filename}
                  </button>
                ))
            ) : (
              <p>
                <i className="fa-light fa-paperclip-vertical"></i> No
                attachments
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="d-flex flex-column bottom-nav row-gap-05 container-fluid">
        <button type="button" className="btn w-100 red" onClick={handleCancel}>
          NO
        </button>
        <button
          type="button"
          className="btn w-100 green"
          onClick={handleStartJob}
        >
          YES
        </button>
      </div>
    </>
  ) : (
    'loading..'
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
    jobs: state.jobs,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StartJob);
