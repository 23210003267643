import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  CommaNumber_NoDecimal,
  DateAndTime_AsString,
  DateAndTime_AsString_NoHtml,
  Date_AsString,
  FriendlyTimeFromMinutes,
  statusText,
} from '../../util/formatter';
import Gravatar from 'react-gravatar';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { getJobById, startJob } from '../../actions/jobActions';
import {
  loadAttachmentsForJob,
  downloadAttachment,
  saveAttachment,
  uploadAttachment,
  getAttachmentBase64,
} from '../../actions/jobAttachmentActions';
import {
  loadCommentsForJob,
  saveComment,
} from '../../actions/jobCommentActions';
import jobStatuses from '../../actions/jobStatuses';
import AttachmentImage from './AttachmentImage';

function ViewJob({ currentUser, jobs }) {
  const params = useParams();
  const navigate = useNavigate();
  const [job, setJob] = useState();
  const [reloadJob, setReloadJob] = useState();
  const [reloadComments, setReloadComments] = useState();
  const [jobComments, setJobComments] = useState();
  const [jobAttachments, setJobAttachments] = useState();
  const [isSaving, setIsSaving] = useState(false);

  const uploadRef = useRef();
  const uploadMaterialsRef = useRef();

  const [showMenu, setShowMenu] = useState(false);
  useEffect(() => {
    if (params.id) {
      setIsSaving(true);
      // Get the job
      getJobById(params.id).then((j) => {
        setJob(j);
        setIsSaving(false);
      });
    }
  }, [params.id, setJob, reloadJob]);

  useEffect(() => {
    if (params.id) {
      // Get the job
      loadCommentsForJob(params.id).then((d) => {
        setJobComments(d);
      });
    }
  }, [params.id, setJobComments, reloadComments]);

  useEffect(() => {
    if (params.id) {
      // Get the job
      loadAttachmentsForJob(params.id).then((d) => {
        setJobAttachments(d);
      });
    }
  }, [params.id, setJobAttachments]);

  useEffect(() => {
    if (jobs && jobs.find((x) => x.id === params.id)) {
      setReloadJob(new Date());
      setReloadComments(new Date());
    }
  }, [jobs, params.id]);

  const handleViewOnMap = (location) => {
    window.open(
      `https://www.google.com/maps/search/${encodeURIComponent(location)}`
    );
  };

  const handleDownload = (attachment) => {
    downloadAttachment(attachment.jobAttachmentId);
  };

  const handleStartJob = () => {
    // check for things that must be read
    if (jobAttachments && jobAttachments.some((x) => x.readRequiredOnStart)) {
      navigate(`/job/${params.id}/start`);
    } else {
      startJob(params.id).then(() => {
        toast.success('Job started');
        setReloadJob(new Date());
        setReloadComments(new Date());
        setShowMenu(false);
      });
    }
  };

  const handleImageOnChange = (e) => {
    const {
      target: { files },
    } = e;
    let postData = {
      jobId: params.id,
      attachmentTypeId: 3,
      description: files[0].name,
      file: files[0],
    };
    setIsSaving(true);
    uploadAttachment(postData)
      .then((attachment) => {
        saveComment({
          jobId: params.id,
          comment: files[0].name,
          jobAttachmentIds: [attachment.id],
        }).then(() => {
          setReloadComments(new Date());
          setShowMenu(false);
          setIsSaving(false);
        });
      })
      .catch((e) => {
        toast.error('Failed to add image');
        console.error(e);
        setIsSaving(false);
      });
  };

  const handleMaterialsOnChange = (e) => {
    const {
      target: { files },
    } = e;
    let postData = {
      jobId: params.id,
      attachmentTypeId: 1,
      description: files[0].name,
      file: files[0],
    };
    setIsSaving(true);
    uploadAttachment(postData)
      .then((attachment) => {
        saveComment({
          jobId: params.id,
          comment: `Materials : ${files[0].name}`,
          jobAttachmentIds: [attachment.id],
        }).then(() => {
          setReloadComments(new Date());
          setShowMenu(false);
          setIsSaving(false);
        });
      })
      .catch((e) => {
        toast.error('Failed to add image');
        console.error(e);
        setIsSaving(false);
      });
  };

  return job && jobComments && jobAttachments && !isSaving ? (
    <>
      <div className="container-fluid page-title">
        <Link to="/">
          <i className="fa-light fa-chevron-left text-white me-2"></i>
          <h1>{job.workOrder}</h1>
        </Link>
      </div>

      <div className="d-flex flex-column flex-1-0-auto overflow-y-scroll hide-scroll">
        <div className="container-fluid d-flex flex-wrap pb-3 pt-3">
          <div className="col">
            <label>Scheduled</label>
            <span>{Date_AsString(job.scheduledStartDate, '', true)}</span>
          </div>
          <div
            className={`d-flex flex-column col job-status ${
              job.completedDate
                ? 'started'
                : job.startedDate
                ? 'in-progress'
                : 'not-started'
            }`}
          >
            <i
              className={`${
                job.completedDate
                  ? 'fa-solid fa-circle-check'
                  : job.startedDate
                  ? 'fa-solid fa-circle-check'
                  : 'fa-light fa-hourglass'
              } icon-1-7 mb-1`}
            ></i>
            <span>{statusText(job.jobStatusId)}</span>
          </div>
        </div>
        <div className="container-fluid pb-3 bt">
          <div className="col">
            <div className="mt-3">
              <label>Customer</label>
              <span>{job.customerName}</span>
            </div>
            <div className="mt-3 d-flex">
              <div className="d-flex flex-column">
                <label>Location</label>
                <span>{job.location}</span>
              </div>
              <span
                className="icon-3 ms-3 d-flex align-items-center text-blue"
                onClick={() => handleViewOnMap(job.location)}
              >
                <i className="fa-light fa-circle-location-arrow"></i>
              </span>
            </div>
            <div className="mt-3">
              <label>Description</label>
              <span>{job.description}</span>
            </div>
            <div className="mt-3">
              <label>Attachments</label>
              <div className="attachments btn-group-vertical w-100 mt-2">
                {jobAttachments && jobAttachments.length > 0 ? (
                  jobAttachments.map((x) => (
                    <button
                      key={x.jobAttachmentId}
                      type="button"
                      className="btn w-100"
                      onClick={() => handleDownload(x)}
                    >
                      <i className="fa-light fa-file-lines"></i>
                      {x.description ?? x.filename}
                    </button>
                  ))
                ) : (
                  <p>
                    <i className="fa-light fa-paperclip-vertical"></i> No
                    attachments
                  </p>
                )}
              </div>
            </div>
            <div className="mt-3">
              <div className="notes">
                <label>Notes</label>
                {jobComments.length > 0 ? (
                  jobComments.map((x) => (
                    <div className="card" key={x.jobCommentId}>
                      <div className="card-body text-white">
                        <p>{x.comment}</p>
                        {x.jobAttachmentDtos &&
                          x.jobAttachmentDtos.length > 0 &&
                          x.jobAttachmentDtos.map((photo) => (
                            <AttachmentImage
                              key={photo.jobAttachmentId}
                              alt={photo.description}
                              id={photo.jobAttachmentId}
                            />
                          ))}
                      </div>
                      <div className="card-body d-flex justify-space-between bt">
                        <span className="small-text">
                          {' '}
                          {x.createdByFullName}
                        </span>
                        <span className="small-text">
                          {DateAndTime_AsString_NoHtml(
                            x.updatedDate ?? x.creationDate
                          )}
                        </span>
                      </div>
                    </div>
                  ))
                ) : (
                  <span>
                    <i className="fa-light fa-note me-2"></i>No notes
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showMenu === false && (
        <div className="d-flex flex-column bottom-nav row-gap-05 container-fluid">
          <button
            type="button"
            onClick={() => {
              setShowMenu(true);
            }}
            className="btn w-100 blue"
          >
            ACTIONS
          </button>
        </div>
      )}

      {showMenu === true && (
        <div className="d-flex flex-column bottom-nav row-gap-05 container-fluid has-overlay">
          {job.jobStatusId < jobStatuses.INPROGRESS && (
            <button
              type="button"
              className="btn w-100 green"
              onClick={handleStartJob}
            >
              START JOB
            </button>
          )}

          {job.jobStatusId === jobStatuses.INPROGRESS && (
            <Link to={`/stopJob/check/${params.id}`}>
              <button type="button" className="btn w-100 red">
                STOP JOB
              </button>
            </Link>
          )}

          {job.jobStatusId >= jobStatuses.INPROGRESS && (
            <>
              <button
                type="button"
                className="btn w-100 blue has-icon-right"
                onClick={() => uploadRef.current.click()}
              >
                ADD PHOTO
                <i className="fa-solid fa-camera"></i>
                <input
                  ref={uploadRef}
                  type="file"
                  accept="image/*"
                  capture
                  style={{ display: 'none' }}
                  onChange={handleImageOnChange}
                />
              </button>
              <button
                type="button"
                className="btn w-100 blue has-icon-right"
                onClick={() => uploadMaterialsRef.current.click()}
              >
                ADD MATERIALS
                <i className="fa-solid fa-camera"></i>
                <input
                  ref={uploadMaterialsRef}
                  type="file"
                  accept="image/*"
                  capture
                  style={{ display: 'none' }}
                  onChange={handleMaterialsOnChange}
                />
              </button>
              <Link to={`/addNote/${params.id}`}>
                <button type="button" className="btn w-100 blue has-icon-right">
                  ADD NOTES
                  <i className="fa-solid fa-file-lines"></i>
                </button>
              </Link>
            </>
          )}
          <button
            type="button"
            onClick={() => {
              setShowMenu(false);
            }}
            className="btn w-100 outline has-icon-left"
          >
            BACK
            <i className="fa-solid fa-chevron-left"></i>
          </button>
        </div>
      )}
    </>
  ) : (
    <div className="d-flex justify-center align-items-center h-100">
      <img src={'/img/loader.gif'} width="100px" height="100px" />
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
    jobs: state.jobs,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ViewJob);
