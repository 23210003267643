import dateRanges from '../actions/dateRanges';
import {
  apiUrl,
  getList,
  getSingleRecord,
  putAction,
  saveRecord,
  get,
} from './apiUtils';
const baseUrl = apiUrl + '/jobs/';

export function getJobs(filters, _scopes, _sort, _order, _page, _limit) {
  //let url = `${baseUrl}list?fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}`;
  let url = `${baseUrl}list`;
  return getList(url, filters, _scopes, _sort, _order, _page, _limit);
}

export function getJobById(id) {
  return getSingleRecord(baseUrl, id);
}

export function saveJob(job) {
  return saveRecord(baseUrl, job);
}

export function assignJob(jobid, userid) {
  if (userid) {
    return putAction(`${baseUrl}${jobid}/assign/${userid}`);
  }
  return putAction(`${baseUrl}${jobid}/unassign`);
}

export function scheduleJob(jobid, scheduledDate) {
  return putAction(
    `${baseUrl}${jobid}/schedule/${scheduledDate.toISOString()}`
  );
}

export function startJob(jobid) {
  return putAction(`${baseUrl}${jobid}/start`);
}

export function stopJob(jobid) {
  return putAction(`${baseUrl}${jobid}/stop`);
}

export function requestReschedule(jobid, comment) {
  return putAction(`${baseUrl}${jobid}/requestReschedule`, { comment });
}

export function requestSecondVisit(jobid, comment) {
  return putAction(`${baseUrl}${jobid}/requestSecondVisit`, { comment });
}
