import { Link } from 'react-router-dom';

export default function BurgerMenu() {
  return (
    <>
      <header className="container-fluid">
        <div className="col">
          <img src={'/img/logo.svg'} alt="logo" />
        </div>
      </header>
    </>
  );
}
