import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { loadJobs } from '../../actions/jobActions';
import dateRanges from '../../actions/dateRanges';

function Jobs({ stateJobs }) {
  const [jobsToday, setJobsToday] = useState();
  const [jobsTomorrow, setJobsTomorrow] = useState();
  const [reload, setReload] = useState();

  useEffect(() => {
    loadJobs({
      status: null,
      filters: null,
      _scopes: { dateRange: dateRanges.TODAY, assignedToMe: true },
      _sort: 'assignedOrder',
      _order: '',
      _page: 1,
      _limit: 100,
    }).then((d) => {
      setJobsToday(d.data);
    });
    loadJobs({
      status: null,
      filters: null,
      _scopes: { dateRange: dateRanges.TOMORROW, assignedToMe: true },
      _sort: 'assignedOrder',
      _order: '',
      _page: 1,
      _limit: 100,
    }).then((d) => {
      setJobsTomorrow(d.data);
    });
  }, [reload]);

  useEffect(() => {
    if (stateJobs) {
      setReload(new Date());
    }
  }, [stateJobs]);

  const job = (x) => {
    return (
      <Link to={`/job/${x.id}`} key={x.id}>
        <div className="card job">
          <div className="card-body d-flex">
            <div
              className={`job-status ${
                x.completedDate
                  ? 'started'
                  : x.startedDate
                  ? 'in-progress'
                  : 'not-started'
              }`}
            >
              <i
                className={`${
                  x.completedDate
                    ? 'fa-solid fa-circle-check'
                    : x.startedDate
                    ? 'fa-solid fa-circle-check'
                    : 'fa-light fa-hourglass'
                } icon-1-7 mb-1`}
              ></i>
            </div>
            <div className="job-info">
              <h3>{x.location}</h3>
              <span className="Job-num">{x.workOrder}</span>
            </div>
            <div className="arrow">
              <i className="fa-light fa-chevron-right"></i>
            </div>
          </div>
          <div className="card-body job-address text-white">
            <i className="fa-light fa-building me-2 text-blue"></i>{' '}
            {x.customerName}
          </div>
          <div className="card-body job-desc bt">{x.description}</div>
        </div>
      </Link>
    );
  };

  const jobs = (x) => {
    return x ? (
      x && x.length > 0 ? (
        x
          .sort((a, b) => {
            return a.order < b.order;
          })
          .map((x) => job(x))
      ) : (
        <div>no jobs yet</div>
      )
    ) : (
      <div className="card justify-center align-items-center">
        <img src={'/img/loader-card.gif'} width="100px" height="100px" />
      </div>
    );
  };

  return (
    <>
      <div className="container-fluid page-title ">
        <h1>Your jobs</h1>
      </div>
      <div className="d-flex flex-column flex-1-0-auto overflow-y-scroll hide-scroll">
        <div className="container-fluid pb-3 pt-3">
          <h2 className="mb-3">Today</h2>
          {jobs(jobsToday)}

          <h2 className="mt-3 mb-3">Tomorrow</h2>
          {jobs(jobsTomorrow)}
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
    stateJobs: state.jobs,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);
