import React from 'react';
import jobStatuses from '../actions/jobStatuses';

/**
 * Returns a 123456.00 as 123,456
 * @param {any} data
 * @param {any} type
 * @param {any} row
 */
export function CommaNumber_NoDecimal(data) {
  return data == null
    ? ''
    : data.toLocaleString('en-GB', { minimumFractionDigits: 0 });
}

/**
 * Returns a 123456.00 as 123,456.00
 * @param {any} data
 * @param {any} type
 * @param {any} row
 */
export function CommaNumber_WithDecimal(data) {
  return data == null
    ? ''
    : data.toLocaleString('en-GB', { minimumFractionDigits: 2 });
}

/**
 * Returns a '2016-04-28T09:19:55.343' as '28/04/2016<br><small>09:19:55</small>'
 * @param {any} data
 * @param {any} type
 * @param {any} row
 */
export function Date_AsString(data, defaultText, noFriendly) {
  if (data === null) {
    return defaultText ?? '';
  }

  let twoDigitPad = (num) => {
    return num < 10 ? '0' + num : num;
  };

  let date = new Date(data);
  if (noFriendly != true && new Date() - date < 5 * 60 * 1000) {
    return <span title={date.toLocaleString()}>Just now</span>;
  } else {
    return <>{date.toLocaleDateString()}</>;
  }
}

/**
 * Returns a '2016-04-28T09:19:55.343' as '28/04/2016<br><small>09:19:55</small>'
 * @param {any} data
 * @param {any} type
 * @param {any} row
 */
export function DateAndTime_AsString(data, defaultText) {
  if (data === null) {
    return defaultText ?? '';
  }

  let twoDigitPad = (num) => {
    return num < 10 ? '0' + num : num;
  };

  let date = new Date(data);
  if (new Date() - date < 5 * 60 * 1000) {
    return <span title={date.toLocaleString()}>Just now</span>;
  } else {
    return (
      <>
        {date.toLocaleDateString()} : {twoDigitPad(date.getHours())}:
        {twoDigitPad(date.getMinutes())}
      </>
    );
  }
}

/**
 * Returns a '2016-04-28T09:19:55.343' as '28/04/2016 09:19:55'
 * @param {any} data
 * @param {any} type
 * @param {any} row
 */
export function DateAndTime_AsString_NoHtml(data, valueIfNull) {
  if (data === null || data === '') {
    return valueIfNull || '';
  }

  let twoDigitPad = (num) => {
    return num < 10 ? '0' + num : num;
  };

  let date = new Date(data);
  if (new Date() - date < 5 * 60 * 1000) {
    return <span title={date.toLocaleString()}>Just now</span>;
  } else {
    return (
      <>
        {date.toLocaleDateString()} {twoDigitPad(date.getHours())}:
        {twoDigitPad(date.getMinutes())}
      </>
    );
  }
}

export function TryFormatJson(data) {
  try {
    return JSON.stringify(JSON.parse(data), null, 4);
  } catch {
    return data;
  }
}
export function FriendlyTimeFromMinutes(minutes) {
  return FriendlyTimeFromSeconds(minutes * 60);
}
export function FriendlyTimeFromSeconds(seconds) {
  if (seconds) {
    let months = Math.floor(seconds / 2628000);
    let days = Math.floor(seconds / 86400);
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor(seconds / 60);
    let remainingMinutes = Math.floor(minutes - hours * 60);
    let years = Math.floor(months / 12);
    let remainingMonths = Math.floor(months - years * 12);

    if (years >= 1) {
      if (years > 1) {
        if (remainingMonths >= 1) {
          return `${years} years ${remainingMonths} months`;
        } else {
          return `${years} years`;
        }
      } else {
        if (remainingMonths >= 1) {
          return `${years} year ${remainingMonths} months`;
        } else {
          return `${years} year`;
        }
      }
    } else if (months >= 1) {
      if (months > 1) {
        return `${months} months`;
      } else {
        return `${months} month`;
      }
    } else if (days >= 1) {
      if (days > 1) {
        return `${days} days`;
      } else {
        return `${days} day`;
      }
    } else if (hours >= 1) {
      if (hours > 1) {
        if (remainingMinutes >= 1) {
          return `${hours} hours ${remainingMinutes} minutes`;
        } else {
          return `${hours} hours`;
        }
      } else {
        if (remainingMinutes >= 1) {
          return `${hours} hour ${remainingMinutes} minutes`;
        } else {
          return `${hours} hour`;
        }
      }
    } else {
      if (minutes > 1) {
        return `${minutes} mins`;
      } else {
        // make this 1 minute to prevent problems if elapsed time is 59 seconds or less?
        return `1 min`;
      }
    }
  } else {
    return '0 min';
  }
}

export function statusText(status) {
  if (status === jobStatuses.COMPLETE) {
    return 'Complete';
  }
  if (status === jobStatuses.INPROGRESS) {
    return 'In progress';
  }
  if (status === jobStatuses.INVOICED) {
    return 'Invoiced';
  }
  if (status === jobStatuses.NEEDSINVOICING) {
    return 'Needs invoicing';
  }
  if (status === jobStatuses.NEEDSPRICING) {
    return 'Needs pricing';
  }
  if (status === jobStatuses.NEEDSRESCHEDULING) {
    return 'Needs scheduling';
  }
  if (status === jobStatuses.NEEDSSECONDVISIT) {
    return 'Needs second visit';
  }
  if (status === jobStatuses.READY) {
    return 'Ready to start';
  }
  if (status === jobStatuses.UNASSIGNED) {
    return 'Unassigned';
  }
  return '';
}
