import * as jobApi from '../api/jobApi';

export function loadJobs({
  status,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  _scopes.status = status;
  return jobApi.getJobs(filters, _scopes, _sort, _order, _page, _limit);
}

export function getJobById(id) {
  return jobApi.getJobById(id);
}

export function saveJob(job) {
  return jobApi.saveJob(job);
}

export function assignJob(jobid, userid) {
  if (userid === '') {
    userid = null;
  }
  return jobApi.assignJob(jobid, userid);
}

export function scheduleJob(jobid, scheduledDate) {
  return jobApi.scheduleJob(jobid, scheduledDate);
}

export function startJob(jobid) {
  return jobApi.startJob(jobid);
}

export function stopJob(jobid) {
  return jobApi.stopJob(jobid);
}

export function requestReschedule(jobid, comment) {
  return jobApi.requestReschedule(jobid, comment);
}

export function requestSecondVisit(jobid, comment) {
  return jobApi.requestSecondVisit(jobid, comment);
}
