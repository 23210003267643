import { Outlet } from 'react-router-dom';
import BurgerMenu from '../components/layout/BurgerMenu';

export default function Layout() {
  return (
    <div className="wrapper">
      <BurgerMenu></BurgerMenu>
      <Outlet />
    </div>
  );
}
