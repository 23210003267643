export default function NotFound() {
  return (
    <div className="error-page">
      <img src={'/img/logo.svg'} alt="Bellcorp" />
      <h2>Page Not Found</h2>
      <a href="/" className="btn blue mt-3">
        <i className="fas fa-chevron-left"></i> Take me back to WMS
      </a>
    </div>
  );
}
