import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  requestReschedule,
  requestSecondVisit,
  stopJob,
} from '../../actions/jobActions';
import { saveComment } from '../../actions/jobCommentActions';

export default function StopJob4SecondVisitReason({ id }) {
  const params = useParams();
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);

  const done = () => navigate(`/stopJob/secondvisitphoto/${params.id}`);

  const focusInput = useRef();
  useEffect(() => {
    const timer = setTimeout(() => {
      focusInput?.current && focusInput.current.focus();
    }, 5);
    return timer;
  }, []);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const { ref, ...rest } = register('comment');

  const onSubmit = (data) => {
    if (data.comment) {
      setIsSaving(true);
      requestSecondVisit(params.id, data.comment).then(() => {
        setIsSaving(false);
        done();
      });
    } else {
      toast.error('You must supply a reason');
    }
  };
  const handleCancel = () => navigate(-1);

  return !isSaving ? (
    <>
      <div className="container-fluid page-title">
        <span className="a" onClick={handleCancel}>
          <i className="fa-light fa-chevron-left text-white"></i>
          <h1>REQUEST SECOND VISIT</h1>
        </span>
      </div>
      <div className="d-flex flex-column flex-1-0-auto overflow-y-scroll hide-scroll">
        <div className="container-fluid pt-3 pb-3 d-flex flex-column flex-1-0-auto">
          <div className="form-control d-flex flex-column flex-1-0-auto">
            <textarea
              {...register('comment', {})}
              className="flex-1-0-auto"
              autoFocus={true}
              ref={(e) => {
                ref(e);
                focusInput.current = e; // you can still assign to ref
              }}
              required
              placeholder="Why this job needs a second visit..."
            ></textarea>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column bottom-nav row-gap-05 container-fluid">
        <button
          type="button"
          className="btn w-100 green has-icon-right"
          onClick={handleSubmit(onSubmit)}
        >
          <i className="fa-solid fa-chevron-right"></i>
          NEXT
        </button>
        <button
          type="button"
          className="btn w-100 outline has-icon-left"
          onClick={handleCancel}
        >
          <i className="fa-solid fa-chevron-left"></i>
          BACK
        </button>
      </div>
    </>
  ) : (
    <div className="d-flex justify-center align-items-center h-100">
      <img src={'/img/loader.gif'} width="100px" height="100px" />
    </div>
  );
}
