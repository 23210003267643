import { Link } from 'react-router-dom';

export default function AddPhoto() {
  return (
    <>
      <div className="container-fluid page-title">
        <h1>Add Photo</h1>
      </div>

      <div className="d-flex flex-column flex-1-0-auto overflow-y-scroll hide-scroll">
        <div className="container-fluid d-flex flex-wrap pb-3 pt-3">
          <div className="col">
            <label>Is this image correct</label>
          </div>
        </div>
        <div className="card">
          <div className="card-body text-white">
            <img src={'/img/Image1.jpg'} alt="Image1" className="img-resp" />
          </div>
        </div>
      </div>

      <div className="d-flex flex-column bottom-nav row-gap-05 container-fluid">
        <Link to="">
          <button type="button" className="btn w-100 green">
            YES SAVE PHOTO
          </button>
        </Link>{' '}
        <Link to="/job/1/">
          <button type="button" className="btn w-100 outline has-icon-left">
            <i className="fa-solid fa-chevron-left"></i>
            BACK
          </button>
        </Link>{' '}
      </div>
    </>
  );
}
