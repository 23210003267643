import { BrowserRouter, Route, Routes } from 'react-router-dom';
import store from './store';
import { Provider as ReduxProvider } from 'react-redux';
import Jobs from './components/jobs/Jobs';
import StartJob from './components/jobs/StartJob';
import ViewJob from './components/jobs/ViewJob';
import AddNote from './components/jobs/AddNote';
import AddPhoto from './components/jobs/AddPhoto';
import AddMaterials from './components/jobs/AddMaterials';
import Layout from './components/Layout';
import NotFound from './components/NotFound';

import SigninOidc from './components/auth/signin-oidc';
import SignoutOidc from './components/auth/signout-oidc';
import SilentRenewOidc from './components/auth/silent-renew-oidc';
import AccessDenied from './components/AccessDenied';
import ApiFailure from './components/ApiFailure';

import MustAuth from './components/auth/MustAuth';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StopJob1 from './components/jobs/StopJob1';
import StopJob2Photo from './components/jobs/StopJob2Photo';
import StopJob3Reschedule from './components/jobs/StopJob3Reschedule';
import StopJob4SecondVisitReason from './components/jobs/StopJob4SecondVisitReason';
import StopJob4SecondVisitPhoto from './components/jobs/StopJob4SecondVisitPhoto';

function App() {
  return (
    <>
      <ToastContainer autoClose="3000" hideProgressBar />
      <ReduxProvider store={store}>
        <BrowserRouter>
          <Routes>
            <Route exact path="/access-denied" element={<AccessDenied />} />
            <Route exact path="/api-failure" element={<ApiFailure />} />
            <Route exact path="/signout-oidc" element={<SignoutOidc />} />
            <Route exact path="/signin-oidc" element={<SigninOidc />} />
            <Route
              exact
              path="/silent-renew-oidc"
              element={<SilentRenewOidc />}
            />
            <Route element={<MustAuth />}>
              <Route element={<Layout />}>
                <Route path="/" element={<Jobs />} />
                <Route path="/addNote/:id" element={<AddNote />} />
                <Route path="/stopJob/check/:id" element={<StopJob1 />} />
                <Route path="/stopJob/photo/:id" element={<StopJob2Photo />} />
                <Route
                  path="/stopJob/reschedule/:id"
                  element={<StopJob3Reschedule />}
                />
                <Route
                  path="/stopJob/secondvisit/:id"
                  element={<StopJob4SecondVisitReason />}
                />
                <Route
                  path="/stopJob/secondvisitphoto/:id"
                  element={<StopJob4SecondVisitPhoto />}
                />
                <Route path="/addPhoto" element={<AddPhoto />} />
                <Route path="/addMaterials" element={<AddMaterials />} />
                <Route path="/job/:id" element={<ViewJob />}></Route>
                <Route path="/job/:id/start" element={<StartJob />}></Route>
              </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </ReduxProvider>
    </>
  );
}

export default App;
