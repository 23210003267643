import React, { useState, useEffect } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { getJobById, startJob, stopJob } from '../../actions/jobActions';

function StopJob1({ currentUser, jobs }) {
  const params = useParams();
  const navigate = useNavigate();
  const [job, setJob] = useState();
  const [reloadJob, setReloadJob] = useState();

  useEffect(() => {
    if (params.id) {
      // Get the job
      getJobById(params.id).then((j) => {
        setJob(j);
      });
    }
  }, [params.id, setJob, reloadJob]);

  useEffect(() => {
    if (jobs && jobs.find((x) => x.id === params.id)) {
      setReloadJob(new Date());
    }
  }, [jobs, params.id]);

  const done = () => navigate(`/job/${params.id}`);
  const handleCancel = () => navigate(`/job/${params.id}`);

  return job ? (
    <>
      <div className="container-fluid page-title">
        <span className="a" onClick={handleCancel}>
          <i className="fa-light fa-chevron-left text-white"></i>
          <h1>STOP JOB</h1>
        </span>
      </div>

      <div className="d-flex flex-column flex-1-0-auto overflow-y-scroll hide-scroll align-items-center justify-center no-data">
        <i className="fa-light fa-circle-question"></i>
        <span className="mt-3">What is the status of this job?</span>
        <label className="text-center mt-3">{job.workOrder}</label>
      </div>

      <div className="d-flex flex-column bottom-nav row-gap-05 container-fluid ">
        <Link to={`/stopJob/photo/${params.id}`}>
          <button
            type="button"
            onClick={() => {}}
            className="btn w-100 green has-icon-right"
          >
            JOB IS COMPLETE
            <i className="fa-solid fa-check"></i>
          </button>
        </Link>
        <Link to={`/stopJob/secondvisit/${params.id}`}>
          <button
            type="button"
            onClick={() => {}}
            className="btn w-100 blue has-icon-right"
          >
            JOB NEEDS SECOND VISIT
            <i className="fa-solid fa-plus"></i>
          </button>
        </Link>
        <Link to={`/stopJob/reschedule/${params.id}`}>
          <button
            type="button"
            onClick={() => {}}
            className="btn w-100 blue has-icon-right"
          >
            PLEASE RESCUEDULE JOB
            <i className="fa-solid fa-calendar"></i>
          </button>
        </Link>
        <button
          className="btn w-100 outline has-icon-left"
          onClick={handleCancel}
        >
          <i className="fa-solid fa-chevron-left"></i>
          BACK
        </button>
      </div>
    </>
  ) : (
    <div className="d-flex justify-center align-items-center h-100">
      <img src={'/img/loader.gif'} width="100px" height="100px" />
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
    jobs: state.jobs,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StopJob1);
