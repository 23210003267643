import actionTypes from './actionTypes';

import * as usersApi from '../api/usersApi';
import Cookies from 'universal-cookie';

export function storeUser(user) {
  return function (dispatch) {
    const cookies = new Cookies();
    cookies.set('token', user.access_token);
    const username = user.profile.preferred_username ?? user.profile.name;
    //send a temp user object to prevent redirect loop - may have to await some loader or other
    dispatch({
      type: actionTypes.STORE_USER,
      user: { username, isLoading: true },
    });
    //get the actual user from the api
    return usersApi
      .getCurrentUser()
      .then((currentUser) => {
        dispatch({
          type: actionTypes.USER_SIGNED_IN,
          user: { ...currentUser, access_token: user.access_token },
        });
      })
      .catch((e) => {
        if (e.message === 'Failed to fetch') {
          dispatch({
            type: actionTypes.API_FAILURE,
          });
        } else {
          dispatch({
            type: actionTypes.ACCESS_DENIED,
          });
        }
      });
  };
}

export function loadingUser() {
  return {
    type: actionTypes.LOADING_USER,
  };
}

export function storeUserError() {
  return {
    type: actionTypes.STORE_USER_ERROR,
  };
}

export function userExpired() {
  return {
    type: actionTypes.USER_EXPIRED,
  };
}

export function userSignedOut() {
  return function (dispatch) {
    return dispatch({
      type: actionTypes.USER_SIGNED_OUT,
    });
  };
}

export function clearUser() {
  return function (dispatch) {
    return dispatch({
      type: actionTypes.CLEAR_USER,
    });
  };
}

export function clearProperties() {
  return function (dispatch) {
    return dispatch({
      type: actionTypes.USER_CLEAR_PROPERTIES,
    });
  };
}
