import actionTypes from '../actions/actionTypes';
export default function (state = null, action) {
  switch (action.type) {
    case actionTypes.STORE_USER:
    case actionTypes.USER_SIGNED_IN:
      return action.user;
    case actionTypes.ACCESS_DENIED:
      return { ...state, accessDenied: true };
    case actionTypes.API_FAILURE:
      return { ...state, apiFailure: true };
    case actionTypes.USER_PERMISSIONS_CHANGED:
      return { ...state, ...action.user, permissionsChanged: true };
    case actionTypes.USER_CLEAR_PROPERTIES:
      //deconstruct it, throw away properties
      const { accessDenied, apiFailure, permissionsChanged, ...user } = state;
      return user;
    case actionTypes.USER_SIGNED_OUT:
      return null;
    default:
      return state;
  }
}
