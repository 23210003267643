import React, { useState, useEffect, useRef } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { getJobById, startJob, stopJob } from '../../actions/jobActions';
import { saveComment } from '../../actions/jobCommentActions';
import { uploadAttachment } from '../../actions/jobAttachmentActions';

function StopJob2Photo({ currentUser, jobs }) {
  const params = useParams();
  const navigate = useNavigate();
  const [job, setJob] = useState();
  const [reloadJob, setReloadJob] = useState();
  const [isSaving, setIsSaving] = useState(false);

  const uploadRef = useRef();

  useEffect(() => {
    if (params.id) {
      // Get the job
      getJobById(params.id).then((j) => {
        setJob(j);
      });
    }
  }, [params.id, setJob, reloadJob]);

  useEffect(() => {
    if (jobs && jobs.find((x) => x.id === params.id)) {
      setReloadJob(new Date());
    }
  }, [jobs, params.id]);

  const done = () => navigate(`/`);
  const handleCancel = () => navigate(`/job/check/${params.id}`);

  const handleImageOnChange = (e) => {
    const {
      target: { files },
    } = e;
    let postData = {
      jobId: params.id,
      attachmentTypeId: 3,
      description: files[0].name,
      file: files[0],
    };
    setIsSaving(true);
    uploadAttachment(postData)
      .then((attachment) => {
        saveComment({
          jobId: params.id,
          comment: files[0].name,
          jobAttachmentIds: [attachment.id],
        }).then(() => {
          stopJob(params.id)
            .then(() => {
              toast.success('Job stopped');
              done();
            })
            .catch((e) => {
              toast.error('Failed to stop job');
              console.error(e);
              setIsSaving(false);
            });
        });
      })
      .catch((e) => {
        toast.error('Failed to add image');
        console.error(e);
        setIsSaving(false);
      });
  };

  return job && !isSaving ? (
    <>
      <div className="container-fluid page-title">
        <span className="a" onClick={handleCancel}>
          <i className="fa-light fa-chevron-left text-white"></i>
          <h1>STOP JOB</h1>
        </span>
      </div>

      <div className="d-flex flex-column flex-1-0-auto overflow-y-scroll hide-scroll align-items-center justify-center no-data">
        <i className="fa-light fa-camera"></i>
        <span className="mt-3">Add Photo of work carried out</span>
        <label className="text-center mt-3">{job.workOrder}</label>
      </div>

      <div className="d-flex flex-column bottom-nav row-gap-05 container-fluid">
        <button
          type="button"
          className="btn w-100 blue has-icon-right"
          onClick={() => uploadRef.current.click()}
        >
          ADD PHOTO
          <i className="fa-solid fa-camera"></i>
          <input
            ref={uploadRef}
            type="file"
            accept="image/*"
            capture
            style={{ display: 'none' }}
            onChange={handleImageOnChange}
          />
        </button>
        <button
          type="button"
          onClick={handleCancel}
          className="btn w-100 outline has-icon-left"
        >
          BACK
          <i className="fa-solid fa-chevron-left"></i>
        </button>
      </div>
    </>
  ) : (
    <div className="d-flex justify-center align-items-center h-100">
      <img src={'/img/loader.gif'} width="100px" height="100px" />
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
    jobs: state.jobs,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StopJob2Photo);
