import React, { useState, useEffect, useRef } from 'react';
import { saveComment } from '../../actions/jobCommentActions';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';

export default function AddNote({ id }) {
  const params = useParams();
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);

  const focusInput = useRef();
  useEffect(() => {
    const timer = setTimeout(() => {
      focusInput?.current && focusInput.current.focus();
    }, 5);
    return timer;
  }, []);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const { ref, ...rest } = register('comment');

  const onSubmit = (data) => {
    if (data.comment) {
      setIsSaving(true);
      saveComment({
        jobId: params.id,
        comment: data.comment,
        jobAttachmentIds: [],
      }).then(() => {
        toast.success('Note added');
        setIsSaving(false);

        // navigate back to job
        navigate(-1);
      });
    } else {
      toast.error('You must supply a note');
    }
  };
  const handleCancel = () => navigate(-1);

  return (
    <>
      <div className="container-fluid page-title">
        <h1>Add note</h1>
      </div>
      <div className="d-flex flex-column flex-1-0-auto overflow-y-scroll hide-scroll">
        <div className="container-fluid pt-3 pb-3 d-flex flex-column flex-1-0-auto">
          <div className="form-control d-flex flex-column flex-1-0-auto">
            <textarea
              {...register('comment', {})}
              className="flex-1-0-auto"
              autoFocus={true}
              ref={(e) => {
                ref(e);
                focusInput.current = e; // you can still assign to ref
              }}
              required
              placeholder="Add note here..."
            ></textarea>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column bottom-nav row-gap-05 container-fluid">
        <button
          type="button"
          className="btn w-100 green"
          onClick={handleSubmit(onSubmit)}
        >
          SAVE NOTE
        </button>
        <button
          type="button"
          className="btn w-100 outline has-icon-left"
          onClick={handleCancel}
        >
          <i className="fa-solid fa-chevron-left"></i>
          BACK
        </button>
      </div>
    </>
  );
}
