import actionTypes from '../actions/actionTypes';

export function configureSubscriptions(connection, dispatch) {
  connection.on('JobCreated', (job) => {
    dispatch({
      type: actionTypes.JOB_CREATED,
      job,
    });
  });
  connection.on('JobUpdated', (job) => {
    dispatch({
      type: actionTypes.JOB_UPDATED,
      job,
    });
  });
  connection.on('JobDeleted', (job) => {
    dispatch({
      type: actionTypes.JOB_DELETED,
      job,
    });
  });
}
