import React, { useEffect } from 'react';
import { signinSilentCallback } from '../../services/userService';

function SilentRenewOidc() {
  useEffect(() => {
    signinSilentCallback();
  }, []);
  return <div>Silently refreshed token...</div>;
}

export default SilentRenewOidc;
